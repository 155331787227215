@import '../../../_theme_variables';

.sdsarticleCat, #sdsblogArticle, #articleComments {
	.title_block {
		&:after {
			display:none;	
		}
	}
}

/****************************** Blog blocks styles ********************************/

#latestComments, #blogPopular, #recent_article_smart_blog_block_left {
	ul {
		li {
			&.last_item {
				border:none;
				margin-bottom:0;
				padding-bottom:0;	
			}
		}
	}
}

#blogPopular, #recent_article_smart_blog_block_left {
	.info {
		display:block;
		font-style:italic;	
	}
}

#latestComments {
	.products-block-image {
		border:none;	
	}
}

/*************************** end Blog blocks styles *******************************/

/*************************** articles listing styles ******************************/
.title_block_exclusive {
	padding:0 0 0 0;
	margin:0 0 20px 0;
	font:400 25px/1.2em $font-custom;
	color:#555459;
	text-transform:uppercase;
	
	a {
		color:#555459;
	
		&:hover {
			color:$link-hover-color;	
		}
	}
}

#sdsblogCategory {
	margin-bottom:30px;
	.imageFeatured {
		float: left;
    	margin: 0 20px 10px 0;
	}	
}

#smartblogcat {
	margin-bottom:0;	
}

.sdsarticleCat {
	margin-bottom:30px;

	.articleContent {
	
		.post-image {
			@include post-image;
			
			img {
				width:100%;	
			}
		}
		
		.sdsarticle-des {
			margin-bottom:30px;	
		}
	}
}

.articleHeader {
	overflow:hidden;
	margin-bottom:30px;
	.postInfo {
		> span {
			padding-left:10px;	
		}
		a {
			text-decoration:underline;
			&:hover {
				text-decoration:none;
			}
		}
	}
}

a.read-more {
	text-decoration:underline;
	&:hover {
		text-decoration:none;
	}	
}

.post-count {
	float:left;
	padding:5px 0 0 0;
}
/*********************** end articles listing styles ******************************/

/************************** article info styles ***********************************/

#sdsblogArticle {
	
	.post-image {
		@include post-image;
		
		img {
			width:100%;	
		}
	}
	
	.sdsarticle-des {
		margin-bottom:30px;	
	}
	
	.sdstags-update {
		margin-bottom:30px;	
	}
	
	.block-addthis {
		margin-bottom:30px;	
	}
	@media (max-width: $screen-xs - 1) { // max 479px
		.addthis_default_style .addthis_counter {
			clear:left;	
		}
		.at-share-tbx-element a.at300b, .at-share-tbx-element a.at300b {
			clear: left;
			display: block;
			margin-bottom: 10px;
			width: auto;
		}
	}
}

/************************ end of article info styles ******************************/

#sdsblogArticle .attention img {
    margin: 0 10px;
}
/*************************************** Blog comments *****************************/
#comments #commentTitle {
    display: none;
}

#comments {
	img.avatar {
		float:left;
		margin-right:15px;
		margin-bottom:5px;
		border:1px solid $base-border-color;
	}
	li li {
		.well {
			margin-top:20px;	
		}
	}
}

#respond {
	background:#fff;
}
#respond #commentInput {
	background:#fff;
	padding:0 10px 10px 10px;	
}

#respond #replyTitle {
    display: none;
}

#respond table {
    width: 100%;
}

#respond .smartblogcaptcha {
    width: 100px;
}

#respond td {
    padding: 3px 5px;
}
#respond .note {
    color: #888888;
    display: block;
    font-size: 10px;
}
#respond td:first-child {
    padding-top: 6px;
    vertical-align: top;
}

#respond .text-right {
    padding: 10px 5px 0px;
}

#sdssearch_block_top {
  padding-top: 0px;
}

#sdssearch_block_top #searchbox {
    float: left;
    width: 100%;
}

#sdssearch_block_top .btn.button-search {
    background: #333333;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    color: white;
    width: 50px;
    text-align: center;
    padding: 10px 0 11px 0;
	font-weight:normal;
	&:hover {
		background:#000;
	}
}

#sdssearch_block_top .btn.button-search span {
      display: none;
}

#sdssearch_block_top .btn.button-search:before {
      content: "\f002";
      display: block;
      font-family: "FontAwesome";
      font-size: 17px;
      width: 100%;
      text-align: center;
}

#sdssearch_block_top .btn.button-search:hover {
      color: #6f6f6f;
}

#sdssearch_block_top #search_query_top {
    display: inline;
    padding: 0 13px;
    height: 45px;
    line-height: 45px;
    background: #fbfbfb;
    margin-right: 1px;
}

form#searchbox {
  position: relative;
}

form#searchbox label {
    color: #333333;
}
form#searchbox input#search_query_block {
    margin-right: 10px;
    max-width: 222px;
    margin-bottom: 10px;
    display: inline-block;
    float: left;
}
form#searchbox .button.button-small {
    float: left;
}
form#searchbox .button.button-small i {
      margin-right: 0;
}


ul.commentList ul {
	padding-left:70px;
}

.commentList {
	.well {
		background:#fff;
		@include box-shadow(rgba(0,0,0,0) 0 0 0);
		@media (max-width: $screen-xs-max) { // max 767px
			position:relative;
			padding-bottom:50px;
		}
	}
	.created, .name {
		margin-bottom:8px;	
	}
	.comment-reply-link.btn {
		font-size:12px;
		line-height:15px;
		
		&:hover, &:active, &:focus {
			
		}
	}
	.pull-right {
		@media (max-width: $screen-xs-max) { // max 767px
			position:absolute;
			right:5px;
			bottom:5px;
			.comment-reply-link.btn {
				font-size:11px;
				line-height:14px;
				padding:5px 10px;
			}
		}	
	}
}

#articleRelated {
	margin-bottom:15px;
	li {
		padding-bottom:15px;
		
		@media only screen and (min-width:$screen-md-min){
			&.first-in-line {
				clear:left;	
			}
		}
		@media only screen and (min-width:$screen-sm) and (max-width:$screen-sm-max){
			&.first-item-of-tablet-line {
				clear:left;	
			}
		}
		
		@media only screen and (min-width:$screen-xs) and (max-width:$screen-xs-max){
			width:50%;
			float:left;
			text-align:center;
			&.first-item-of-mobile-line {
				clear:left;	
			}
		}
		@media only screen and (max-width:$screen-xs-max){
			.img-responsive {
				width:100%;	
			}	
		}
	}
	.products-block-image {
		margin-bottom:10px;
		display:block;	
	}
	.post-name {
		font-weight:bold;	
	}
}